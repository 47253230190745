import moment from 'moment';

// Configure English calendar format
moment.locale('en', {
  calendar: {
    sameDay: '[Today,] MMM D',
    nextDay: '[Tomorrow,] MMM D',
    nextWeek: 'ddd, MMM D',
    sameElse: 'ddd, MMM D',
  },
});

// Configure Hebrew calendar format
moment.locale('he', {
  calendar: {
    sameDay: '[היום,] MMM D',
    nextDay: '[מחר,] MMM D',
    nextWeek: 'dddd, MMM D',
    sameElse: 'dddd, MMM D',
  },
});

export const getDateInfo = (dateString: string, locale: 'en' | 'he') => {
  // Set the moment locale based on the input
  moment.locale(locale);

  // Parse the date as UTC
  const deliveryDate = moment.utc(dateString).startOf('day');
  const today = moment.utc().startOf('day');

  // Calculate the difference in calendar days from today
  const daysDifference = deliveryDate.diff(today, 'days');

  // Format the date in "MMM D, YYYY" format
  const formattedDate = deliveryDate.format('MMM D, YYYY');

  // Generate the calendar-based delivery info
  const deliveryInfo = deliveryDate.calendar(); // Uses the calendar format based on the current date

  // Handle singular/plural days for the delivery in X days
  const dayText =
    locale === 'en'
      ? daysDifference === 1
        ? 'day'
        : 'days'
      : daysDifference === 1
        ? 'יום'
        : 'ימים';

  let deliveryDetail = '';
  if (daysDifference > 0) {
    deliveryDetail =
      locale === 'en'
        ? `Delivery in ${Math.abs(daysDifference)} ${dayText} (${deliveryDate.format('ddd')} ${deliveryDate.format('D')})`
        : `משלוח בעוד ${Math.abs(daysDifference)} ${dayText} (${deliveryDate.format('ddd')} ${deliveryDate.format('D')})`;
  }

  // Custom format for "Today, Jan 19", "Tomorrow, Jan 20", etc.
  let customFormattedDate = '';
  if (daysDifference === 0) {
    customFormattedDate = locale === 'en'
      ? `Today, ${deliveryDate.format('MMM D')}`
      : `היום, ${deliveryDate.format('MMM D')}`;
  } else if (daysDifference === 1) {
    customFormattedDate = locale === 'en'
      ? `Tomorrow, ${deliveryDate.format('MMM D')}`
      : `מחר, ${deliveryDate.format('MMM D')}`;
  } else {
    customFormattedDate = locale === 'en'
      ? `${deliveryDate.format('dddd')}, ${deliveryDate.format('MMM D')}`
      : `${deliveryDate.format('dddd')} ,${deliveryDate.format('MMM D')}`;
  }

  // If the locale is Hebrew, manually adjust the weekday and month formatting
  if (locale === 'he') {
    // Hebrew weekdays
    const hebrewWeekdays = ['ראשון', 'שני', 'שלישי', 'רביעי', 'חמישי', 'שישי', 'שבת'];
    const weekdayIndex = deliveryDate.day(); // Get the index of the weekday
    const hebrewWeekday = hebrewWeekdays[weekdayIndex];

    customFormattedDate = customFormattedDate.replace(deliveryDate.format('dddd'), hebrewWeekday);

    // Hebrew months (if needed)
    const hebrewMonths = [
      'ינואר', 'פברואר', 'מרץ', 'אפריל', 'מאי', 'יוני',
      'יולי', 'אוגוסט', 'ספטמבר', 'אוקטובר', 'נובמבר', 'דצמבר'
    ];
    const monthIndex = deliveryDate.month(); // Get the index of the month (0-11)
    const hebrewMonth = hebrewMonths[monthIndex];

    // Replace the English month with the Hebrew month
    customFormattedDate = customFormattedDate.replace(deliveryDate.format('MMM'), hebrewMonth);
  }

  return {
    daysDifference,
    formattedDate,
    deliveryInfo,
    deliveryDetail, // Dynamic format (English or Hebrew)
    customFormattedDate, // Localized custom format
  };
};
