import moment from 'moment';

export const formatDeliveryDate = (
  isoDate: string
): {
  preText: string;
  formattedDate: string;
} => {
  // Parse the delivery date as UTC
  const deliveryDate = moment.utc(isoDate);

  let preText = '';
  let formattedDate = '';

  // Create moments for today and tomorrow in UTC
  const today = moment.utc().startOf('day');
  const tomorrow = moment.utc().add(1, 'days').startOf('day');

  // Check if the delivery date is today
  if (deliveryDate.isSame(today, 'day')) {
    preText = 'deliveryIsToday';
  }

  // Check if the delivery date is tomorrow
  else if (deliveryDate.isSame(tomorrow, 'day')) {
    preText = 'deliveryIsTomorrow';
  }

  // Format for other dates
  else {
    formattedDate = deliveryDate.format('MMM D');
  }

  return {
    preText,
    formattedDate
  }
};
