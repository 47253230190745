import React, { useContext, useEffect, useState } from "react";
import "./item-card.css";
import BasketIcon from "../svg/basket/basket";
import { makeStyles, Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@apollo/client";
import BrokenImageIcon from "../../common/item-card/broken-image";

import { ReactComponent as PlusIcon } from "../../../assets/plus.svg";
import { ReactComponent as MinusIcon } from "../../../assets/minus.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/delete.svg";
import {
  ADD_TO_CART,
  GET_ACTIVE_ORDER,
  GET_TOTAL_LINES,
  REMOVE_FROM_CART,
  REMOVE_ORDER_LINE,
} from "../../../queries/cart.queries.js";
import { h7Medium, pointer } from "../../../shared/constants";
import { ReactComponent as VariantsIcon } from "../../../assets/cardsOptions.svg";
import Loader from "../loader/loader.component";
import { AccountContext } from "../../../context/account.context";
import { CartContext } from "../../../context/cartContext.js";
import { FilterContext } from "../../../context/filterContext";
import { GET_ACTIVE_USER } from "../../../queries/auth.queries";
import { MOVE_STATE } from "../../../queries/checkout.queries";
import { useHistory } from "react-router-dom";
import Tag from "../../../shared/components/tag/Tag.tsx";
import { getDateInfo } from "../../../shared/dates";
import useLocationFromSession from "../../../shared/hooks/useLocationFromSession";

function ItemCard({
  itemCard,
  id,
  title,
  img,
  description,
  weight,
  price,
  showItem,
  cName,
  styles,
  productVariantName,
  merchantLogo,
}) {
  const { t } = useTranslation();
  const navigate = useHistory();
  const dir = t("css.side");
  const { isLocationValid } = useLocationFromSession();

  const useStyle = makeStyles({
    itemCard: {
      height: styles?.itemCard?.height || 350,
      maxHeight: styles?.itemCard?.maxHeight || 350,
      width: styles?.itemCard?.width || 270,
      maxWidth: styles?.itemCard?.maxWidth || 270,
      margin: styles?.itemCard?.margin || 0,
      cursor: "pointer",

      "@media (max-width:940px)": {
        height: styles?.itemCardRes?.height || 272,
        maxHeight: styles?.itemCardRes?.maxHeight || 272,
        width: styles?.itemCardRes?.width || 167,
        maxWidth: styles?.itemCardRes?.maxWidth || 167,
        margin: styles?.itemCardRes?.margin || 0,
      },
    },
    imageSection: {
      height: styles?.imageSection?.height || 184,
      maxHeight: styles?.imageSection?.maxHeight || 184,
      width: styles?.imageSection?.width || "100%",
      maxWidth: styles?.imageSection?.maxWidth || "100%",
      "@media (max-width:940px)": {
        height: styles?.imageSectionRes?.height || 120,
        maxHeight: styles?.imageSectionRes?.maxHeight || 120,
        width: styles?.imageSectionRes?.width || "100%",
        maxWidth: styles?.imageSectionRes?.maxWidth || "100%",
      },
    },
    button: {
      top: styles?.button?.top || 300,
      left: dir === "rtl" ? 0 : styles?.button?.left || 155,
      right: dir === "ltr" ? 0 : styles?.button?.right || 155,

      "@media (max-width:940px)": {
        top: styles?.button?.top || 220,
        left: dir === "rtl" ? 0 : styles?.button?.left || 85,
        right: dir === "ltr" ? 0 : styles?.button?.right || 85,
        display: "flex",
        justifyContent: "space-evenly",
        alignItems: "center",
        alignContent: "center",
      },
    },
  });

  const classes = useStyle();

  const item = {
    id,
    title,
    preview: img,
    name: productVariantName,
    weight,
    price,
  };

  const [isSnackOpen, setIsSnackOpen] = useState(false);
  const [amountValue, setAmountValue] = useState(0);
  const [orderLine, setOrderLine] = useState(null);
  const [itemRemoved, setItemRemoved] = useState(false);
  const [inputValue, setInputValue] = useState(false);
  const { accountState, setAccountState } = useContext(AccountContext);
  const { cartState, setCartState } = useContext(CartContext);
  const { searchState, setSearchState } = useContext(FilterContext);

  const { isAddress } = accountState;

  const { data: activeOrder } = useQuery(GET_ACTIVE_ORDER);
  const { data: userData } = useQuery(GET_ACTIVE_USER);

  const getCurrentQty = (activeOrder) => {
    const { lines } = activeOrder.activeOrder;

    if (!lines.length) return 0;

    const [active] = lines.filter(
      (line) => line.productVariant.id === item?.id
    );

    if (!active) return 0;

    return active?.quantity;
  };

  useEffect(() => {
    let currentQty = activeOrder?.activeOrder?.lines?.length
      ? getCurrentQty(activeOrder)
      : 0;

    setAmountValue(currentQty);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeOrder]);

  useEffect(() => {
    if (
      cartState?.tempItemData?.shouldAdd === true &&
      item.id === cartState?.tempItemData?.id
    ) {
      onAddToCartClicked();
      setCartState((cartState) => ({
        ...cartState,
        tempItemData: {
          id: "",
          shouldAdd: false,
        },
      }));
    }
  }, [cartState.tempItemData]);

  const [onAddToCartClicked, { data: addedClicked, loading: addedLoading }] =
    useMutation(ADD_TO_CART, {
      variables: {
        variantId: id,
        qty: inputValue ? amountValue : 1,
      },
      refetchQueries: [{ query: GET_TOTAL_LINES }, { query: GET_ACTIVE_ORDER }],
      onCompleted: () => {
        setInputValue(false);
        setItemRemoved(false);
        setIsSnackOpen(false);
      },
    });

  const [removeFromCart, { loading: removeFromCartLoading }] = useMutation(
    REMOVE_FROM_CART,
    {
      variables: {
        id: orderLine?.id,
        qty: amountValue - 1,
      },
      refetchQueries: [{ query: GET_TOTAL_LINES }, { query: GET_ACTIVE_ORDER }],
    }
  );

  const [removeOrderLine, { loading: lineRemovedLoading }] = useMutation(
    REMOVE_ORDER_LINE,
    {
      variables: {
        id: orderLine?.id,
      },
      refetchQueries: [{ query: GET_TOTAL_LINES }, { query: GET_ACTIVE_ORDER }],
      onCompleted: () => {
        setAmountValue(0);
        setItemRemoved(true);
        setIsSnackOpen(false);
      },
    }
  );

  const [adjustOrderLine, { loading: adjustLineLoading }] = useMutation(
    REMOVE_FROM_CART,
    {
      variables: {
        id: orderLine?.id,
        qty: amountValue,
      },
      refetchQueries: [{ query: GET_TOTAL_LINES }, { query: GET_ACTIVE_ORDER }],
      onCompleted: () => {
        setItemRemoved(amountValue === 0 ? true : false);
        setIsSnackOpen(false);
      },
    }
  );

  useEffect(() => {
    if (activeOrder) {
      const lines = activeOrder?.activeOrder?.lines;

      if (!lines?.length) return;

      const [active] = lines.filter(
        (line) => line.productVariant.id === item?.id
      );
      setOrderLine(active);
      setAmountValue(active ? active?.quantity : 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeOrder]);

  const onCloseSnack = (bool) => {
    setIsSnackOpen(bool);
  };

  const onEditCartAmount = async () => {
    if (
      isAddress === null &&
      userData?.activeCustomer === null &&
      !isLocationValid
    ) {
      setAccountState({
        ...accountState,
        isAddress: null,
        isDeliveryModalAddress: true,
      });
      let itemId = item.id;
      setCartState((cartState) => ({
        ...cartState,
        tempItemData: {
          ...cartState.tempItemData,
          id: itemId,
        },
      }));
      return;
    }
    await addItemToOrder();
  };

  const [moveStateToAddingItems] = useMutation(MOVE_STATE, {
    variables: {
      state: "AddingItems",
    },
    refetchQueries: [{ query: GET_ACTIVE_USER }],
    onCompleted: async () => await onAddToCartClicked(),
  });

  const addItemToOrder = async () => {
    if (
      !activeOrder?.activeOrder ||
      activeOrder?.activeOrder?.state === "AddingItems"
    ) {
      await onAddToCartClicked();
    }
    if (
      activeOrder?.activeOrder?.state === "ArrangingPayment" ||
      activeOrder?.activeOrder?.state === "Open"
    ) {
      await moveStateToAddingItems();
    }
  };

  const onActionClicked = async (bol) => {
    if (isNaN(amountValue)) return;

    let qty = activeOrder?.activeOrder?.lines?.length
      ? getCurrentQty(activeOrder)
      : 0;

    if (bol) {
      await onAddToCartClicked();
      if (addedClicked) {
        setAmountValue(+qty + 1);
      }
    } else {
      if (amountValue <= 0) return;
      if (!activeOrder?.activeOrder?.lines?.length) return;
      if (amountValue > qty) return;
      if (amountValue === 1) {
        await removeOrderLine();
      } else {
        await removeFromCart();
      }
    }
  };

  const changeOrderQty = async (qty) => {
    if (qty === 0) {
      await removeOrderLine();
      return;
    }
    if (qty !== 0 && amountValue === 0) {
      await setInputValue(true);
      await setAmountValue(+qty);
      await onAddToCartClicked();
      return;
    }
    if (qty !== 0 && amountValue !== 0) {
      if (!inputValue) {
        await setAmountValue(+qty);
        await adjustOrderLine();
        return;
      } else {
        if (addedClicked) {
          await setAmountValue(+qty);
          await adjustOrderLine();
        }
      }
    }
  };

  const Alert = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  };

  const navigateEvent = (event, direction = "") => {
    event.stopPropagation();

    if (direction === "changeClicked" || direction === "none") {
      return;
    }
    if (direction === "changed") {
      changeOrderQty(event.target.value);
      return;
    }
    if (direction === "delete") {
      onActionClicked(false);
      return;
    }
    if (direction === "plus") {
      onActionClicked(true);
      return;
    }
    if (direction === "add") {
      onEditCartAmount();
      return;
    }
    if (direction === "merchant") {
      setSearchState({
        ...searchState,
        previousPage: navigate.location.pathname,
      });
      navigate.push(`/supplier/${itemCard?.merchantSlug}`);
      return;
    }
    navigate.push(`/item/${itemCard?.slug}`);
  };

  const { deliveryDelayed, deliveryDate } = itemCard || {};
  let delayedDate = null;

  if (deliveryDelayed) {
    const date = getDateInfo(deliveryDate, dir === "ltr" ? 'en' : 'he');

    delayedDate = date?.deliveryInfo;
  }

  return (
    <React.Fragment>
      <div
        className={`item-card ${classes.itemCard} ${cName}`}
        onClick={(e) => navigateEvent(e)}
      >
        {deliveryDelayed ? (
          <div style={{ position: "absolute", top: 18, right: 16 }}>
            <Tag
              text="Delayed Delivery"
              backgroundColor="#15222F"
              color="#FFF"
            />
          </div>
        ) : null}
        <div className="item-card__container">
          <div
            className={`item-card__img-section pointer ${classes.imageSection}`}
          >
            {img ? (
              <img
                className="item-card__img-section--img"
                src={img}
                alt="item-card-img"
              ></img>
            ) : (
              <BrokenImageIcon style={{ height: "100%" }} />
            )}
          </div>
          <div className="item-card__details-section">
            <div onClick={(e) => navigateEvent(e, "merchant")}>
              <div className="item-card__details-section__merchant">
                {merchantLogo ? (
                  <div className="item-card__details-section__logo">
                    <img
                      src={merchantLogo}
                      alt="logo"
                      width={"100%"}
                      height={"100%"}
                    />
                  </div>
                ) : null}
                <span className="item-card__details-section__title">
                  {title}
                </span>
              </div>
            </div>
            <span className="item-card__details-section__description">
              {description}
            </span>
            {productVariantName && (
              <div style={{ display: "flex" }}>
                {itemCard?.productVariantCount > 1 && (
                  <>
                    <VariantsIcon />
                    <span
                      style={{ ...h7Medium, color: "#43AF75", ...pointer }}
                      onClick={showItem}
                    >
                      {`${itemCard?.productVariantCount} ${t(
                        "itemPage.options"
                      )}`}
                    </span>
                  </>
                )}
              </div>
            )}

            <span className="item-card__details-section__weight">{weight}</span>
            {delayedDate ? <span className="item-card__details-section__weight">{delayedDate}</span> : null}

            <span className="item-card__details-section__price">
              <span style={{ fontFamily: "Assistant" }}>₪</span>
              {`${(price / 100).toFixed(2)}`}
            </span>
            {amountValue > 0 ? (
              <button
                className={`add-button h5-medium ${classes.button}`}
                style={{ justifyContent: "space-between" }}
              >
                <div className="IC__actions">
                  {addedLoading ||
                    lineRemovedLoading ||
                    adjustLineLoading ||
                    removeFromCartLoading ? (
                    <div className="IC__actions">
                      <Loader color={"#B73232"} size={14} />
                    </div>
                  ) : (
                    <>
                      {amountValue === 1 ? (
                        <DeleteIcon
                          className="pointer"
                          onClick={(e) => navigateEvent(e, "delete")}
                        />
                      ) : (
                        <MinusIcon
                          className="pointer"
                          onClick={(e) => navigateEvent(e, "delete")}
                        />
                      )}
                    </>
                  )}
                </div>
                <span
                  className="h5-medium IC__amount-display"
                  onClick={(e) => navigateEvent(e, "none")}
                >
                  {amountValue}
                </span>
                <div className="IC__actions">
                  {addedLoading ||
                    lineRemovedLoading ||
                    adjustLineLoading ||
                    removeFromCartLoading ? (
                    <div className="IC__actions">
                      <Loader color={"#B73232"} size={14} />
                    </div>
                  ) : (
                    <PlusIcon
                      className="pointer"
                      onClick={(e) => navigateEvent(e, "plus")}
                    />
                  )}
                </div>
              </button>
            ) : (
              <button
                className={`green add-button h5-medium ${classes.button}`}
                onClick={(e) => navigateEvent(e, "add")}
              >
                {addedLoading ? (
                  <Loader color={"#B73232"} size={20} />
                ) : (
                  <>
                    <i>
                      <BasketIcon stroke={"white"} />
                    </i>
                    {t("common.Add")}
                  </>
                )}
              </button>
            )}
          </div>
        </div>
      </div>
      <Snackbar
        open={isSnackOpen}
        autoHideDuration={1000}
        onClose={() => onCloseSnack(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={() => onCloseSnack(false)}
          severity={itemRemoved ? "error" : "success"}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              src={img}
              alt={item?.name}
              style={{
                height: 50,
                width: 50,
                borderRadius: 5,
                marginRight: 15,
              }}
            />
            <span>
              {t("itemPage.ItemEditMessage", {
                action: itemRemoved
                  ? `${t("itemPage.removed")}`
                  : `${t("itemPage.added")}`,
              })}
            </span>
          </div>
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
}

export default ItemCard;
