import React, { useState } from 'react';
import { useMutation } from "@apollo/client";
import { makeStyles, Snackbar, Modal, Fade, Backdrop } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { useTranslation } from 'react-i18next';

import { ReactComponent as CancelOrder } from "../../../assets/cancel-order.svg";
import { ReactComponent as RemoveIcon } from "../../../assets/cross.svg";
import { ReactComponent as TrashIcon } from "./trash-icon.svg";

import {
    GET_ACTIVE_ORDER,
    GET_TOTAL_LINES,
} from "../../../queries/cart.queries";
import { GET_ACTIVE_USER } from "../../../queries/auth.queries";

import { CANCEL_ORDER } from "../../../mutations/cancel-order";
import Loader from "../../common/loader/loader.component";
import { h4Medium, h5Medium, h6Medium } from "../../../shared/constants";

const Alert = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const useStyles = makeStyles({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    container: {
        padding: "32px 48px 32px 48px",
        background: "#fff",
        width: 320
    },
    header: {
        display: "flex",
        height: 56,
        justifyContent: "flex-end",
        alignContent: "center",
        alignItems: "center",
        gap: 30,
        paddingBottom: 20,

        "@media (max-width:940px)": {
            height: 32,
        },
    },
    headerTitles: {
        ...h4Medium,
    },
    closeIcon: {
        padding: 16,
        cursor: "pointer"
    },
    AccountCancelOrderButton: {
        margin: "32px 0 24px 0",
        height: 56,
        background: "#80BB34",
        color: "#FFF",
        ...h5Medium,
        border: "none",
        width: "100%",
        borderRadius: 16,
        cursor: "pointer"
    },
    notNow: {
        ...h6Medium,
        color: "#80BB34",
        display: "flex",
        justifyContent: "center",
        cursor: "pointer"
    }
});

const AccountCancelOrder = ({ order, handleCloseParentModal }) => {
    const { t } = useTranslation();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isOrderSnackOpen, setIsOrderSnackOpen] = useState(false);

    const classes = useStyles();

    const [cancelOrder, { loading: cancelOrderLoader }] = useMutation(CANCEL_ORDER, {
        variables: { id: order?.id },
        refetchQueries: [{ query: GET_TOTAL_LINES }, { query: GET_ACTIVE_ORDER }, { query: GET_ACTIVE_USER }],
        awaitRefetchQueries: true,
        onCompleted: (data) => {
            if (data?.cancelOpenOrder?.__typename && data?.cancelOpenOrder?.__typename === "Order") {
                setIsOrderSnackOpen(true);
                setTimeout(() => {
                    handleClose();
                    handleCloseParentModal();
                }, 500)
            } else {
                alert('Something went wrong!')
            }
        },
    });

    const onCancelOrder = async () => {
        cancelOrder();
    };

    const handleClose = () => {
        setIsModalOpen(false);
    };

    const handleSnackbarClose = () => {
        setIsOrderSnackOpen(false);
    };

    const header = (
        <div className={classes.header}>
            <div className={classes.headerTitles}>
                <span className='h4-medium'>{t("account.cancelThisOrder")}</span>
            </div>
            <div className={classes.closeIcon}
                onClick={handleClose}>
                <RemoveIcon
                    style={{ height: 24, width: 24 }}
                />
            </div>
        </div>
    );

    return <>
        <span style={{ alignItems: "center", color: "#f50050", cursor: "pointer", display: "flex", gap: 8, ...h6Medium }} onClick={() => {
            setIsModalOpen(true);
        }}>
            <TrashIcon />
            {t("account.cancelOrder")}
        </span>

        <Modal
            className={classes.modal}
            open={isModalOpen}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={isModalOpen}>
                <div className={classes.container}>
                    {header}
                    <CancelOrder />
                    <button className={classes.AccountCancelOrderButton} onClick={onCancelOrder}>
                        {cancelOrderLoader ? <Loader color={"#B73232"} size="15" /> : t("account.cancelOrder")}
                    </button>
                    <span className={classes.notNow} onClick={handleClose}>{t("account.notNow")}</span>
                </div>
            </Fade>
        </Modal>
        <Snackbar
            open={isOrderSnackOpen}
            autoHideDuration={1000}
            onClose={handleSnackbarClose}
            anchorOrigin={{
                vertical: "top",
                horizontal: `${t("css.snackBarPosition")}`,
            }}
        >
            <Alert onClose={handleSnackbarClose} severity="success">
                <div style={{ display: "flex", alignItems: "center" }}>
                    <span>
                        {t("itemPage.ItemEditMessage", {
                            action: `${t("itemPage.cancelled")}`,
                        })}
                    </span>
                </div>
            </Alert>
        </Snackbar>
    </>;
};

export default AccountCancelOrder;